html {
    scroll-behavior: smooth;
}

html, body {
    height: 100% !important;
}

* {
    font-family: "Nunito Sans", sans-serif
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.p-component h1 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #555;
    line-height: 1.16;
}

.p-component h2 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.19;
    color: #555;
}

.p-component h3 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: #555;
}

.p-component .text-sm {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #555;
}

.p-component label {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 13px;
    line-height: 1.69;
    color: #555;
}

.p-card {
    border-radius: 8px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
    padding: 25px;
}

.p-card .p-card-body {
    padding: 0;
}
